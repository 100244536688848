//site front color
$color1: #e7e7e7; //couleur du texte global
$color2: #242424; //couleur fond footer et encar dépôts
$color3: #555555; //couleur fond bannière-header et second carousel accueil
$color4: #ffffff; //couleur fond menu secondaire et titres produits catalogue
$color5: #ffffff; //couleur navbar et fond des encadrés accueil
$color7: rgb(98, 98, 98); //couleur du texte de la navbar




//non utilisé
$color6: #ffffff;
$color8: black;

/* XS - SM */
@media screen and (max-width: 767px){ 
    .h1Landing {
        font-size: 4rem !important;
    }
    .h2FontLanding {
        font-size: 2rem !important;
    }
}  
/* MD */
@media screen and (min-width: 768px) and (max-width: 991px){ 
    .h1Landing {
        font-size: 5rem !important;
    }
    .h2FontLanding {
        font-size: 3rem !important;
    }
} 
/* LG - XL - XXL */
@media screen and (min-width: 992px) { 
    .h1Landing {
        font-size: 9rem !important;
    }
    .h2FontLanding {
        font-size: 4rem !important;
    }
} 




//Modifications des couleurs par rapport à la version précédente

.normalFont {
    color:$color7 !important;
}

.texteAccueilContainer {
    padding: 3.5rem;
    background-color: $color5;
}

.borderTitresProduits {
    // border: 2px solid #BC7B56;
}

.containerInfosRightCarousel {
    // border: thick double rgb(136, 136, 136);
    // background: linear-gradient($color2, $color4);
    // border-radius: 20px 0px 0px 0px !important;
}

.containerInfosRightCarousel>.card-header {
    background-color: $color2;
    color: $color1 !important;
    // font-weight: bold !important;
    // border-radius: 15px 0px 0px 0px !important;
}

.containerInfosRightCarousel>.card-body {
    background-color: $color2;
    color: $color1 !important;
    // font-weight: bold !important;
}
//intérieur de la carte "La tesserie etc..."
.card-body>.infosHeader, .card-body>.card-title>.infosHeader {
    color: $color1;
    // font-weight: bold !important;
}

.infosHeader {
    font-size: 1.4rem;
    font-weight: bold !important;
    // color: $color1 !important;
}



a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    color: $color1;
}

.btn:hover {
    color: $color3;
}


//Fin Modifications des couleurs par rapport à la version précédente



.footer {
    background-color: $color2;
}

//site admin color
$adminColor1: #514343;
$adminColor2: #92302c;

.color1 {
    background-color: $color1;
}
.color2 {
    background-color: $color2;
    color: $color1;
}
.color3 {
    background-color: $color3;
    color: $color1;
}
.color4 {
    background-color: $color4;
    color: $color2;
}
.color5 {
    background-color: $color5;
    color: $color2;
}
.color6 {
    background-color: $color6;
    color: $color1;
}

.colorAdmin1 {
    background-color: $adminColor1;
    color: $color1;
}
.colorAdmin2 {
    background-color: $adminColor2;
    color: $color1;
}

body {
    background-color: $color1;
}



.carouselContainer {
    padding-top: 0rem !important;
    max-width: 90rem;
    z-index: 4 !important;
}

.stickyCustom {
    // position: fixed;
    width: 100%;
    z-index: 1;
}

.bg-dark {
    background-color: $color2;
}

@font-face {
    font-family: "h1Font";
    src: url("../fonts/NotoSansSC-Medium.otf");
}
@font-face {
    font-family: "h2Font";
    src: url("../fonts/DancingScript-VariableFont_wght.ttf");
}
.mainFont {
    font-family: h1Font;
}
.navFont {
    font-family: h1Font;
    font-size: 1.1rem !important;
    color: $color1 !important;
    // background-color: sur .navbarColor;
}

@font-face {
    font-family: "h1FontTitrePrincipal";
    src: url("../fonts/titreLanding/Parisienne-Regular.ttf");
}

.h1FontTitrePrincipal {
    font-family: h1FontTitrePrincipal !important;
}

.h1FontTitrePrincipalHeader {
    font-size: 4rem !important;
}

.h1Font {
    font-size: 3rem;
    font-family: h1Font;
    color: $color1;
}
.h2Font {
    font-size: 2.3rem;
    font-family: h2Font;
    //pas la même que color1 c'est exprès
    color: rgb(207, 207, 207);
}
.navbarColor {
    // color sur .navFont
    background-color: $color5 !important;
    // background-image: linear-gradient(15deg, $color3 50%, $color5 100%);
}
.navbarColorAdmin {
    background-color: $adminColor2 !important;
}

.containerAdminColor {
    background-color: $adminColor1;
    // height: 100vh;
}

.containerBgColor {
    background-color: rgb(236, 236, 236);
}

.adminTextAreaHeight {
    height: 10rem;
}

.noColorBtn {
    color: rgb(212, 195, 195);
    background-color: #11ffee00;
    border: none;
}

.fontFormTitles {
    font-size: 1.3rem;
}

.noCss {
    background: none;
    border: none;
    margin: 0 !important;
    padding: 0 !important;
    text-align: left !important;
}

.noCss:focus {
    outline: none;
}

//Bouton retour depuis admin
// .flecheBtnRetourSite {
//     font-size: 1.24rem;
//     margin-top: 30px !important;
// }
// .texteBtnRetourSite {
//     font-size: 1.1rem;
//     line-height: 1rem;
// }
// .btnRetour {
//     padding: 6px;
//     background-color: rgb(95, 95, 95);
//     // position: absolute !important;
//     margin-left: 0.4rem;
//     margin-top: 2rem !important;
// }
//fin Bouton retour

.carousel-control-next,
.carousel-control-prev {
    z-index: 0;
}

/* MD */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .w-md-25 {
        width: 25% !important;
    }
}
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .w-lg-auto {
        width: 100% !important;
    }
}
/* XL */
@media screen and (min-width: 1200px) {
    .w-xl-auto {
        width: auto !important;
    }
}

@font-face {
    font-family: "Arial Nova Light";
    src: url("../fonts/arial-nova-light.ttf");
}

@mixin fontStyle {
    font-family: "Arial Nova Light" !important;
    letter-spacing: 1.3px !important;
}

.fontStyleFinal {
    @include fontStyle;
    font-weight: bold;
}

.fontStyleFinalAdminNav {
    @include fontStyle;
    font-weight: bold !important;
}

.btnCRUD {
    font-size: 1rem;
    border-radius: 50px;
    padding: 10px;
    border: 1px solid;
    border-color: silver !important;
    color: rgb(98, 98, 98);
}

.btnCRUD:focus {
    outline: none !important;
}

.color1 {
    background-color: #fae8d9;
}

.tableTr th {
    font-weight: bold !important;
    font-size: 1.5rem;
    background-color: #dddddd !important;
}

table {
    border-collapse: collapse;
}

thead th {
    border-right: 1px solid black !important;
    vertical-align: middle !important;
}

.containerAdmin {
    width: 90% !important;
    margin: auto;
    // height: 100vh;
}

.noBorder {
    border-right-style: none !important;
}

.containercolor2 {
    background-color: #f0f0f0;
}

.adminTable td {
    display: table-cell;
    vertical-align: middle;
}

// .interfaceAdmin {
//     position: relative !important;
//     margin-top: 30rem !important;
// }

.logo {
    position: absolute !important;
    margin-left: 3rem;
    margin-top: 3rem;
}

.border-radius-cards {
    border-radius: 0px 0px 6px 6px !important;
}

.border {
    border: 1.4px solid black !important;
}

.nomProduit {
    font-size: 1rem;
    padding-top: 8px;
    padding-bottom: 8px;
}

h2 {
    font-size: 1.6rem;
    font-weight: bold !important;
}



.mainContact {
    position: absolute;
    max-width: 54rem;
    margin-top: 13rem;
    // height: 20rem;
}


.maxWidthCarou {
    max-width: 60rem;
}

/* Phone */
@media screen and (max-width: 991px){ 
    .headerContainer {
        min-height: 30rem !important;
    }
} 

.headerContainer {
    min-height: 55rem;
    height: auto;
    width: 100% !important;
    background-image: url("../../public/header/96_dark.jpg");
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}

.textHeaderContainer {
    // opacity: 0.5;
    // background-color: rgb(141, 141, 141);
    // background-color:rgb(255, 255, 255); /* fallback for IE 8 and below */
    background-color:rgba(195, 195, 195, 0.4);

}
.carouselMulti {
    max-height: 220px;
}

/* XS */
.carouselMulti {
    padding-right: 7rem !important;
}

.loginBackground {
    margin-top: 10rem;
}

.adminHeight {
    min-height: 110vh;
}

//pagination aux couleurs de l'admin
.pageItemAdmin.active .page-link {
    background-color: $adminColor2;
    border-color: $adminColor2;
    color: $color1;
}
.page-link {
    color: $adminColor2;
}

//pagination aux couleurs du site côté client
.page-item.active .page-link {
    background-color: $color6;
    border-color: $color2;
    color: $color6;
}
.page-link {
    color: $adminColor2;
}

.mentionsLegalesContainer {
    max-width: 30rem;
    height: 70rem;
}

.custom-file-input {
    font-size: 1.1rem;
}

// @function set-color($color) {
//     @debug lightness($color);
//     @if (lightness($color) >= 40) {
//       @return $color;
//     }
//     @else {
//       @return rgb(189, 24, 24);
//     }
// }

.contactBtn:hover {
    // color : set-color($color3);
    color: $color3 !important;
}


.btn-danger {
    background-color: $adminColor1;
}

.btn-danger:hover {
    color: $color1;
    background-color: $color2;
}