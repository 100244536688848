.tableauChamps {
    max-width: 26rem;
}
/* XS */
@media screen and (max-width: 575px) {
    .inputPhone {
        width: 100% !important;
    }
}
.textAreaHeight {
    height: 130px;
}
.btnOrangeContact {
    color: white;
    font-weight: bold;
}
.btnOrangeContact:hover {
    color: rgb(209, 209, 209);
}