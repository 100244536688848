.ec {
    position: relative;
}
.ec .ec__holder {
    overflow: hidden;
}
.ec .ec__track {
    will-change: transform;
}
.ec .ec__track:after {
    content: "";
    display: table;
    clear: both;
}
.ec .ec__item {
    float: left;
    overflow: hidden;
}
.ec .ec__pagination {
    text-align: center;
    margin-top: 10px;
}
.ec .ec__pagination .pagination__dot {
    width: 8px;
    height: 8px;
    background-color: transparent;
    display: inline-block;
    border: 1px solid #000;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
}
.ec .ec__pagination .pagination__dot--active {
    background-color: #000;
}
.ec .ec__nav {
    position: absolute;
    bottom: 140px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    width: 8px;
    height: 28px;
    cursor: pointer;
}
.ec .ec__nav:before {
    content: "";
}
.ec .ec__nav--prev {
    transform: rotate(-135deg);
    left: 0.3em;
}
.ec .ec__nav--next {
    transform: rotate(45deg);
    right: 1em;
}

@media screen and (max-width: 1400px){ 
    .ec .ec__nav--prev {
        transform: rotate(-135deg);
        left: 2em;
    }
    .ec .ec__nav--next {
        transform: rotate(45deg);
        right: 1.7em;
    }
} 
