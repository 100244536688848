@import 'assets/styles/app';
//$color1
//$color2

.filtreRougeHorizontal {
    border: 2px solid #BC7B56;
    background-color: $color4 !important;
    font-size: 1.1rem;
    width: 22rem;
}

.fontColor4 {
    color: $color2;
    font-weight: bold;
}